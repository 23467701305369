import React, { useContext } from "react"
import Layout from "../../../components/layout"

import ThemeContext from "../../../context/ThemeContext"
import SEO from "../../../components/seo"
import ContentWrapper from "../../../components/text-helpers/content-wrapper"
import MainSearch from "../../../components/main-search/main-search"
import { graphql } from "gatsby"
import localize from "../../../components/localize"

const Content = ({ casinos, translations, locale }) => {
  return (
    <div>
      <MainSearch bonuses={casinos} locale={locale} />
    </div>
  )
}

const Licence = ({ data, pageContext, location }) => {
  let { translations, bonuses } = useContext(ThemeContext)
  const { locale, licence, alternateLocaleSlugs } = pageContext

  bonuses = bonuses
    .filter(x => x.licences)
    .filter(x => x.licences.some(y => y && y.name === licence))

  let pageData = data.sanityLicenceType

  if (!pageData) pageData = {}


  const title = pageData.seoTitle || `${licence} | Mr-Gamble.com`;
  const seoMeta = pageData.seoMeta ||`${licence} | Mr-Gamble.com`;

  return (
    <Layout silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location} bonuses={bonuses} alternateSlug={alternateLocaleSlugs}
           title={title} description={seoMeta} />
      <ContentWrapper
        header={pageData._rawHeader}
        body={pageData._rawContent}
        footer={pageData._rawFooter}
      >
        <Content
          translations={translations}
          locale={locale}
          casinos={bonuses}
        />
      </ContentWrapper>
    </Layout>
  )
}

export default localize(Licence)
export const query = graphql`
  query LicenceQuery($licence: String!) {
  sanitySilo (_id: {eq: "7caede39-eebc-4663-b82c-90ac5520d920"}){
  _id
  breadcrumbTrail {
  ...LocaleSiloTrails
  }
  }
    sanityLicenceType(
      name: { eq: $licence }
    ) {
      name
      seoTitle{
          ...LocaleString
      }

        _rawHeader(resolveReferences: {maxDepth: 10})
        _rawContent(resolveReferences: {maxDepth: 10})
        _rawFooter(resolveReferences: {maxDepth: 10})
      icon {
        asset {
            fluid {
                ...GatsbySanityImageFluid
            }
        }
      }
    }
  }
`
